import {
    HttpClient
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: "root",
})
export class RegiaoService {
  urlAPI: string;

  constructor(
    private _httpClient: HttpClient,
    private _appService: AppService
  ) {
    this.urlAPI = this._appService.getUrlAPINest() + "inclusao-pf/";
    // this.urlAPI = this._appService.getUrlAPI()
  }

  async getEstados(params: any): Promise<any> {
    var retorno = null;

    try {
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `estados/filter`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getCidades(params: any): Promise<any> {
    var retorno = null;

    try {
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `cidades/filter`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }
}

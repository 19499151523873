import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CampanhaService } from 'app/components/campanha/campanha.service';
import { CheckoutService } from '../../components/checkout/checkout.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;

    regiao_seleciona: string
    hostName;
    telaDeCampanha = false;
    parametros_campanha;

    activatedRoute: ActivatedRoute;

    constructor(public location: Location, private element: ElementRef,
        route: ActivatedRoute,
        private router: Router, private campanhaService: CampanhaService,
        private checkoutService: CheckoutService, private platformLocation: PlatformLocation) {
        this.sidebarVisible = false;

        this.activatedRoute = route;

        router.events
            .filter(event => event instanceof NavigationStart)
            .subscribe((event: NavigationStart) => {
                // You only receive NavigationStart events
                this.telaDeCampanha = event && event.url && event.url.indexOf("campanha") >= 0;
            });

    }

    ngOnInit() {
        this.regiao_seleciona = "Selecione"
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.campanhaService.name.subscribe((val) => {
            this.regiao_seleciona = val;
        });

        this.campanhaService.mostrarMenu.subscribe((val) => {
            this.telaDeCampanha = val;

        });

        this.campanhaService.parametros_campanha.subscribe(parametros_campanha => {
            this.parametros_campanha = parametros_campanha;
        });

        this.checkoutService.parametros_campanha.subscribe(parametros_campanha => {
            this.parametros_campanha = parametros_campanha;
        });

        try {
            let regiao_selecionada: any = localStorage.getItem("REGIAO_SELECIONADA")
            if (regiao_selecionada) {
                regiao_selecionada = JSON.parse(regiao_selecionada)
                if (regiao_selecionada) {
                    this.regiao_seleciona = regiao_selecionada.cidade.cid_nome
                    return
                }
            }
        } catch (error) {

        }


    }

    async changeRegiao() {
        this.regiao_seleciona = null;

        localStorage.removeItem("REGIAO_SELECIONADA")
        localStorage.removeItem("PROSPECCAO_SELECIONADA")

        this.campanhaService.broadcastStateChange(null, false);

        if (this.parametros_campanha) {

            let pk_idcampanha = this.parametros_campanha[0];
            let pk_idplataforma = this.parametros_campanha[1];
            let pk_idvendedor = this.parametros_campanha[2];
            this.router.navigate(['/campanha', pk_idcampanha, pk_idplataforma, pk_idvendedor]);

        }/**/


    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }

    scroll(elem: string, offset: number) {
        
        this.router.navigate(['/#/campanha']); // navigate to other page

        const yOffset = offset;
        const element = document.querySelector(elem)!;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;


        window.scrollTo({ top: y, behavior: 'smooth' })

    }


    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }
}

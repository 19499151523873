import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { SemPlanoRegiaoService } from './SemPlanoRegiao.service';
import moment from 'moment';


@Component({
    selector: 'app-modal-SemPlanoRegiao-component',
    styleUrls: ['./SemPlanoRegiao.component.scss'],
    templateUrl: 'SemPlanoRegiao.component.html'
})
export class NgbdModalSemPlanoRegiaoComponent implements OnInit {

    loading = [false];
    submitted = false
    plano_regiao = 161;

    @BlockUI() blockUIPage: NgBlockUI;
    @BlockUI('element') blockUIElement: NgBlockUI;

    public form: FormGroup;

    get f() {
        return this.form.controls;
    }

    @Input() forma_pagamento;

    SemPlanoRegiao
    pla_nome
    fpa_nome
    submittedTitular: boolean = false;
    pk_idtitular: any;
    cod_corretor;

    state: any = {
        carenica: null,
        loading: [false, false, false, false],
        is404: [false, false, false, false],
        disabled: false,
    }



    constructor(private modalService: NgbModal, private fb: FormBuilder, public _service: SemPlanoRegiaoService,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {

    }


    ngOnInit(): void {

        this.form = this.fb.group({
            cli_nome: [null, [Validators.required]],
            cli_email: [null, [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")
            ]],
            cli_telefonecelular: [null, [Validators.required]],
            cli_cidade: [null, [Validators.required]],
            cli_uf: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
            cli_status: ['A'],
            cli_tipo: "LEAD",
            cli_cods4_corretor: this.cod_corretor
        });

        // let nosso = this.cod_corretor;
        // this.SemPlanoRegiao = this.forma_pagamento.pre_descricao
        // this.pla_nome = this.forma_pagamento.pla_nome
        // this.fpa_nome = this.forma_pagamento.fpa_nome
    }

    async refresh() {
        
        localStorage.removeItem("REGIAO_SELECIONADA")
        localStorage.removeItem("PROSPECCAO_SELECIONADA")

        window.location.reload();

    }


    getFormatDateIso(data) {
        var date = moment(data, 'DD-MM-YYYY');
        return date.format('YYYY-MM-DD');
    }

    async onSubmit() {

        try {
            this.submittedTitular = true;

            this.loading[0] = true;
            if (!this.form.valid) {
                this.form.markAllAsTouched();
                return;
            }

            let body = this.form.value;
            // body.cli_datanascimento = this.getFormatDateIso(body.cli_datanascimento);
            body.cli_cods4_corretor = this.cod_corretor

            let o = await this._service.create(body);
            this.pk_idtitular = o.id;
            this.toastr.success('', 'Cadastrado realizado com sucesso. Entraremos em contato em breve.');


            this.activeModal.close(this.form.value)


            //  activeModal.dismiss('Cross click')
            // Mostrando endereco do titular
            //  this.mostrarCadastroEndereco = true;


        } catch (error) {
            console.log(error);
            this.toastr.error('Error', 'Erro interno favor entrar em contato com o suporte');
        } finally {
            this.loading[0] = false;
        }
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';

@Injectable({
    providedIn: 'root'
})
export class SemPlanoRegiaoService {

    constructor(private _httpClient: HttpClient, private _appService: AppService) { }

   
    
  async create(params: any): Promise<any> {

    var retorno = null;

    try {
      const urlAPI = `${this._appService.getUrlAPINest()}inclusao-pf/clientes`
      retorno = await this._httpClient.post<any>(urlAPI, params).toPromise();
    } catch (error) {
      console.log(error)
      throw error
    }

    return retorno;
  }

  async patch(params: any, id): Promise<any> {

    var retorno = null;

    try {
      const urlAPI = `${this._appService.getUrlAPINest()}inclusao-pf/clientes/${id}`
      retorno = await this._httpClient.patch<any>(urlAPI, params).toPromise();
    } catch (error) {
      console.log(error)
      throw error
    }

    return retorno;
  }


  

    // async getPlanoRegiaoPorID(id: number): Promise<any> {

    //     var retorno = null;

    //     try {
    //         debugger       

    //         const urlAPI = `${this._appService.getUrlAPI()}plano_regioes/` +id
    //         retorno = await this._httpClient.get<any>(urlAPI).toPromise();
            
    //         let data = retorno[0];

    //     } catch (error) {
    //         debugger
    //         console.log(error)
    //         throw error
    //     }

    //     return retorno;

    // }



}




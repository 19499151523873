import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidateBrService } from 'angular-validate-br';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DependenteService } from './dependente.service';

@Component({
    selector: 'app-modal-dependente-component',
    styleUrls: ['./dependente.component.scss'],
    templateUrl: 'dependente.component.html'
})
export class NgbdModalDependenteComponent implements OnInit {
    loading = [false];
    submitted = false;
    temTitular = false
    titularSelecionadoForm = false;
    id: any;

    @Input() dependenteView: any;

    public pk_idtitular: number;
    public form: FormGroup;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private validateBrService: ValidateBrService,
        public _service: DependenteService,
        public activeModal: NgbActiveModal,
    ) { }

    get f() {
        return this.form.controls;
    }

    eTitular() {
        return this.id > 0 && this.form.value.cli_titular;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            fk_idtitular: [null, [Validators.required]],
            cli_nome: [null, [Validators.required]],
            cli_email: [null],
            cli_cpf: [null, [Validators.required, this.validateBrService.cpf]],
            cli_telefonecelular: [null],
            cli_datanascimento: [null, [Validators.required, Validators.minLength(10)]],
            cli_sexo: [null, [Validators.required]],
            cli_nomemae: [null, [Validators.required]],
            cli_grauparentesco: [null, [Validators.required]],
            cli_responsavelfinanceiro: [false],
            cli_titular: [false, [Validators.required]],
            cli_status: ['A']
        });

        this.form.get('cli_titular').valueChanges.subscribe(cli_titular => {
            this.titularSelecionadoForm = cli_titular
            this.form.patchValue({ cli_grauparentesco: null })
        })

        this.form.get('cli_datanascimento').valueChanges.subscribe(cli_datanascimento => {
            if (cli_datanascimento) {
                const idade = this.calculateAge(cli_datanascimento);
                if (idade < 18) {
                    this.form.get('cli_cpf').setValidators(this.validateBrService.cpf);
                }
                this.form.get('cli_cpf').updateValueAndValidity();
            }

        });

        if (!this.temTitular) {
            this.form.patchValue({
                cli_titular: true
            })
        }

        setTimeout(() => {
            if (this.dependenteView) {
                this.form.patchValue(this.dependenteView);
            }
        }, 200);
    }

    async onSubmit() {
        try {
            this.submitted = true;
            if (!this.id) {
                this.form.patchValue({
                    fk_idtitular: this.pk_idtitular
                })
            }
            if (this.form.invalid) {
                this.form.markAllAsTouched()
                return;
            }

            const body = Object.assign({}, this.form.value)

            body.cli_datanascimento = this.getFormatDateIso(body.cli_datanascimento);

            this.loading[0] = true;

            if (!this.id) {
                this._service
                    .create(body)
                    .then((result) => {
                        this.toastr.success('Dependente', 'Cadastrado com sucesso');
                        body.pk_idcliente = result.id;
                        this.form.patchValue({
                            pk_idcliente: result.id,
                        });
                        this.activeModal.close(body);
                    })
                    .catch((error) =>
                        this.toastr.error(
                            'Dependente',
                            error?.error?.message ?? 'Erro ao cadastrar'
                        )
                    );
            } else {
                this._service
                    .patch(body, this.id)
                    .then((_) => {
                        body.pk_idcliente = this.id;
                        this.toastr.success('Dependente', 'Alterado com sucesso');
                        this.activeModal.close(body);
                    })
                    .catch((error) =>
                        this.toastr.error(
                            'Dependente',
                            error?.error?.message ?? 'Erro ao fazer alteração'
                        )
                    );
            }
        } finally {
            this.loading[0] = false;
        }

    }

    /**
     * Converte de: dd/mm/yyyy para yyyy-mm-dd to
     * @returns
     */
    getFormatDateIso(data) {
        if (!data || data.indexOf('-') > 0) {
            return data
        }

        const date = moment(data, 'DD/MM/YYYY');
        return date.format('YYYY-MM-DD');
    }

    /**
     * Converte de: yyyy-mm-dd para dd/mm/yyyy
     * @returns
     */
    getFormatIsoToDate(data) {
        if (!data) {
            return
        }
        const date = moment(data, 'YYYY-MM-DD');
        return date.format('DD/MM/YYYY');
    }

    setDependente(obj: any, temTitular: boolean) {
        this.dependenteView = obj;
        this.temTitular = temTitular

        if (this.dependenteView.cli_datanascimento) {
            const datanascimento = this.dependenteView.cli_datanascimento.substring(0, 10);
            this.dependenteView.cli_datanascimento = this.getFormatIsoToDate(datanascimento);
        }

        this.id = obj.pk_idcliente;
    }

    calculateAge(cli_datanascimento) {
        const birthdate = this.getFormatDateIso(cli_datanascimento);
        if (birthdate) {
            return moment().diff(birthdate, 'years');
        }
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';

@Injectable({
    providedIn: 'root'
})
export class CarenciaService {

    constructor(private _httpClient: HttpClient, private _appService: AppService) { }



    async getPlanoRegiaoPorID(id: number): Promise<any> {

        var retorno = null;

        try {
               
            const urlAPI = `${this._appService.getUrlAPINest()}inclusao-pf/plano_regioes/` +id
            retorno = await this._httpClient.get<any>(urlAPI).toPromise();
            
            let data = retorno[0];

        } catch (error) {
            
            console.log(error)
            throw error
        }

        return retorno;

    }



}




import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaisInformacoesService } from './maisInformacoes.service';

@Component({
    selector: 'app-modal-maisInformacoes-component',
    styleUrls: ['./maisInformacoes.component.scss'],
    templateUrl: 'maisInformacoes.component.html'
})
export class NgbdModalMaisInformacoesComponent implements OnInit {

    loading = [false];
    submitted = false
    plano_regiao = 161;
    plano

    @Input() forma_pagamento;

    carencia
    pla_nome
    fpa_nome
    cod_plano

    state: any = {
        carenica: null,
        loading: [false, false, false, false],
        is404: [false, false, false, false],
        disabled: false,
    }


    public form: FormGroup;

    get f() {
        return this.form.controls;
    }

    constructor(private modalService: NgbModal, private fb: FormBuilder, public _service: MaisInformacoesService, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {

    }


    ngOnInit(): void {

        this.BuscarPlano();

        //     this.cod_plano = this.forma_pagamento.pre_descricao
        // this.pla_nome = this.forma_pagamento.pla_nome
        // this.fpa_nome = this.forma_pagamento.fpa_nome
    }


    async BuscarPlano() {

        this.plano = await this._service.getPlanoPorID(this.cod_plano);

        // if (this.plano) {           
            
        // }
    }

}

import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-content',
    styleUrls: ['./contrato.component.scss'],
    template: `
    <div class="modal-header">
    <h5 class="modal-title text-center">Contrato</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">

        <div class="row ">
            <div class="col">
                <!-- Heading -->
                <h4 class="header_card" style="margin: 0;">
                    <b> Clin Smart</b>
                </h4>
                <p class="sem_margem"> Código ANS <b>(342423423)</b></p>
            </div>
            <div class="col col-auto">
                    <button type="button" class="btn btn-primary btn-circle"><i class="fa fa-download"></i> </button>
                    <button type="button" class="btn btn-primary btn-circle"><i class="fa fa-print"></i></button>
            </div>

        </div>

        <hr/>

        <div class="row ">
            <div class="col-2">
            <bSwitch
            [switch-on-color]="'primary'">
        </bSwitch>

            </div>
            <div class="col-10 col-auto">
            <label class="form-switch-label" for="customSwitch1">Declaro que li e aceito o contrato</label>
            </div>

        </div>

       
        <br/>
        <div class="row">
        <div class="col">
        <label class="form-switch-label">Se após baixar o contrato você não cconsiga visualiza-lo, <a href="https://www.adobe.com/br/acrobat/pdf-reader.html" target="_blank">clique aqui</a> para baixar o
        Adobe Reader.</label>
        </div>
  
        </div>

    </div>
    <div class="modal-footer">
        <div class="left-side">
            <button type="button" class="btn btn-default btn-link" (click)="activeModal.close('Close click')">Cancelar</button>
        </div>
        <div class="divider"></div>
        <div class="right-side"> 
            <button type="button" class="btn btn-success  btn-link" (click)="activeModal.close('Close click')">Confirmar</button>
        </div>
    </div>
    `
})
export class NgbdModalContratoContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) { }
}

@Component({
    selector: 'app-modal-contrato-component',
    templateUrl: './contrato.component.html'
})
export class NgbdModalContratoComponent {
    constructor(private modalService: NgbModal) { }
    open() {
        // const modalRef = this.modalService.open(NgbdModalContratoContent);
        const modalRef = this.modalService.open(NgbdModalContratoContent, { size: 'lg', backdrop: 'static' });
        modalRef.componentInstance.name = 'World';
    }

    
}

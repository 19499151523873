import {
  HttpClient
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  parametros_campanha: Subject<string[]> = new Subject();
  urlAPI: string;
  constructor(
    private _httpClient: HttpClient,
    private _appService: AppService
  ) {
    this.urlAPI = this._appService.getUrlAPINest() + "inclusao-pf/";
  }

  async create(params: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}clientes`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `clientes`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async patch(params: any, id): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}clientes/${id}`
      retorno = await this._httpClient
        .patch<any>(this.urlAPI + `clientes/${id}`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async delete(id): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}clientes/${id}`
      retorno = await this._httpClient
        .delete<any>(this.urlAPI + `clientes/${id}`)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  // https://github.com/filipedeschamps/cep-promise
  // consulta três serviços simultaneamente e retorna o primeiro que responder
  // a fazer
  async buscarCEP(cep: string): Promise<any> {
    var retorno = null;

    try {
      retorno = await this._httpClient
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getCidadePorRegiao(params: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}cidades/filter/regiao`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `cidades/filter/regiao`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async enviarTransacaoP4x(params: any): Promise<any> {
    var retorno = null;

    try {
      const urlAPI = `${this._appService.getUrlAPI()}p4x/enviar_transacao`;
      retorno = await this._httpClient
        .post<any>(this.urlAPI, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getVendasPorCPF(params: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}vendas/filter/cpf`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `vendas/filter/cpf`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getByCampanhasPorPlanoRegiao(params: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}campanhas/filter/campanhas_por_plano_regiao`
      retorno = await this._httpClient
        .post<any>(
          this.urlAPI + `campanhas/filter/campanhas_por_plano_regiao`,
          params
        )
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getByCorretorPorCodigoS4(params: any): Promise<any> {
    var retorno = null;
    try {
      //const urlAPI = `${this._appService.getUrlAPI()}contas/getCodigoCorretor`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `contas/getCodigoCorretor`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getByPlataformaPorCodigoS4(params: any): Promise<any> {
    var retorno = null;
    try {
      //const urlAPI = `${this._appService.getUrlAPI()}contas/getCodigoPlataforma`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `contas/getCodigoPlataforma`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getPlanoPorId(id: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}planos/${id}`
      retorno = await this._httpClient
        .get<any>(this.urlAPI + `planos/${id}`)
        .toPromise();

      if (retorno) {
        retorno = retorno[0];
        retorno.plano_regioes_json = JSON.parse(retorno.plano_regioes_json);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async getCampanhaPorId(id: number): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}campanhas/${id}`
      retorno = await this._httpClient
        .get<any>(this.urlAPI + `campanhas/${id}`)
        .toPromise();

      if (retorno) {
        retorno = retorno[0];
      }
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async createVenda(params: any): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}vendas`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `vendas`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async patchVenda(params: any, id): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}vendas/${id}`
      retorno = await this._httpClient
        .patch<any>(this.urlAPI + `vendas/${id}`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async putVenda(params: any, id): Promise<any> {
    var retorno = null;

    try {
      //const urlAPI = `${this._appService.getUrlAPI()}vendas/${id}`
      retorno = await this._httpClient
        .put<any>(this.urlAPI + `vendas/${id}`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async saveStorageLocal(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    //this.localStorage.setItem(key, value);
  }

  // NOVOS ENDPOINTS APONTANDO PARA O BACKEND - NEST
}

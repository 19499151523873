import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "visualizar-contrato",
  templateUrl: "./visualizar-contrato.component.html",
  styleUrls: ["./visualizar-contrato.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VisualizarContratoComponent implements OnInit {
  pla_contrato: string;
  pla_nome: string;

  token: string | undefined;
  recaptchaTouched: boolean = false;
  inputTouched: boolean = false;

  submittedVenda: boolean = false;
  loading = [false];

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  get recaptcha() {
    return this.form.get("recaptcha");
  }

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      acceptanceTerms: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  // async onSubmit() {
  //   this.activeModal.close();
  // }

  async onSubmit() {
    try {
      if (!this.token) {
        this.recaptchaTouched = true;
        return;
      }

      console.log(`Token [${this.token}] generated`);

      if (!this.form.get("acceptanceTerms").value) {
        this.inputTouched = true;
        return;
      }

      this.activeModal.close({ token: this.token });
    } catch (error) {
      console.log(error);
    }
  }

  async onSubmitVenda() {
    try {
      if (!this.token) {
        this.recaptchaTouched = true;
        return;
      }

      this.form.patchValue({
        token_recaptcha: this.token,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getUrlContract() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.pla_contrato);
  }
}

import {
    HttpClient
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  urlAPI: string;
  constructor(
    private _httpClient: HttpClient,
    private _appService: AppService
  ) {
    this.urlAPI = this._appService.getUrlAPINest() + `inclusao-pf/`;
  }

  async create(params: any): Promise<any> {
    var retorno = null;

    try {
      // const urlAPI = `${this._appService.getUrlAPI()}prospeccao`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + 'prospeccao', params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }
}

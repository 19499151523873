import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  NgbDateStruct,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgbdModalCarenciaComponent } from "../modal/carencia/carencia.component";
import { NgbdModalDependenteComponent } from "../modal/dependente/dependente.component";
import { NgbdModalEmailComponent } from "../modal/email/email.component";
import { CheckoutService } from "./checkout.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { Location, LocationStrategy } from "@angular/common";
import { ValidateBrService } from "angular-validate-br";
import { VisualizarContratoComponent } from "../modal/visualizar-contrato/visualizar-contrato.component";
import { NgbdModalRegiaoComponent } from "../modal/regiao/regiao.component";
import { CampanhaService } from "../campanha/campanha.service";

@Component({
  selector: "app-components",
  templateUrl: "checkout.component.html",
  // styles: [`
  // ngb-progressbar {
  //     margin-top: 5rem;
  // }
  // `,
  // './checkout.component.scss']
  styleUrls: ["./checkout.component.scss"],
})
export class ComponentsCheckout implements OnInit, OnDestroy {
  page = 4;
  page1 = 5;
  focus;
  focus1;
  focus2;
  date: { year: number; month: number };
  model: NgbDateStruct;
  prospeccao: any;
  loading = [false];
  readOnlyEnderecoTitular = true;
  readOnlyEndereco = true;
  pk_idtitular: any;
  mostrarCadastroEndereco: boolean = false;
  mostrarCadastroDependente: boolean = false;
  mostrarFormaDePagamento: boolean = false;
  mostrarFormaDePagamentoCartao: boolean = false;
  mostrarFormaDePagamentoBoleto: boolean = false;
  mostrarPossibilidadeParcelameno: boolean = false;

  @ViewChild("divPagamento") divPagamento: ElementRef;

  mostrarContrato: boolean = false;

  pk_idcampanha: any = 20;
  pk_idplano: any = 20;
  pk_idregiao: any = 20;
  pk_idplanoregiao: any;
  cod_vendedor: any = 0;
  cod_plataforma: any = 0;
  cpf_cliente: any = null;
  pk_idCliente_params: any = null;

  plano_selecionado_na_campanha: any = {};
  campanha_selecionada: any = {};
  formas_de_pagamento_por_regiao: any = {};
  formas_de_pagamento_selecionada: any;

  cidade_selecionada: any;
  valor_plano: number = 0;

  submittedTitular: boolean = false;
  submittedVenda: boolean = false;
  submittedEndereco: boolean = false;
  preenchimentoAutomatico: boolean = false;

  temAlgumTitularNaVenda = false;
  cepInvalidoPorRegiao = false;

  token: string | undefined;
  recaptchaTouched: boolean = false;

  @BlockUI() blockUIPage: NgBlockUI;
  @BlockUI("element") blockUIElement: NgBlockUI;

  dependentes: any = [];

  public formGroupTitular: FormGroup;
  public formGroupDependente: FormGroup;
  public formGroupEndereco: FormGroup;
  public formGroupVenda: FormGroup;

  get formTitular() {
    return this.formGroupTitular.controls;
  }

  get formEndereco() {
    return this.formGroupEndereco.controls;
  }

  get formDependete() {
    return this.formGroupDependente.controls;
  }

  get formVenda() {
    return this.formGroupVenda.controls;
  }

  constructor(
    private renderer: Renderer2,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public _service: CheckoutService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _clipboardService: ClipboardService,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private validateBrService: ValidateBrService,
    private campanhaService: CampanhaService
  ) {
    this.token = undefined;
  }
  
  ngOnDestroy(): void {
    localStorage.removeItem('REGIAO_SELECIONADA')
    this.modalService.dismissAll()
  }

  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  ngOnInit() {
    this.formGroupTitular = this.fb.group({
      pk_idcliente: [null],
      fk_idprospeccao: [null],
      fk_idcampanha: [null],
      cli_nome: [null, [Validators.required]],
      cli_email: [null, [Validators.required]],
      cli_cpf: [null, [Validators.required, this.validateBrService.cpf]],
      cli_telefonecelular: [null, [Validators.required]],
      cli_datanascimento: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],
      cli_sexo: [null, [Validators.required]],
      cli_nomemae: [null, [Validators.required]],
      cli_responsavelfinanceiro: [true],
      cli_status: ["A"],
      cli_cods4_corretor: [null],
      cli_titular: [true, [Validators.required]],
    });

    this.formGroupDependente = this.fb.group({
      pk_idcliente: [null],
      fk_idtitular: [null, [Validators.required]],
      cli_nome: [null, [Validators.required]],
      cli_email: [null],
      cli_cpf: [null, [Validators.required, this.validateBrService.cpf]],
      cli_telefonecelular: [null],
      cli_datanascimento: [
        null,
        [Validators.required, Validators.minLength(10)],
      ],
      cli_sexo: [null, [Validators.required]],
      cli_nomemae: [null, [Validators.required]],
      cli_responsavelfinanceiro: [false],
      cli_status: ["A"],
    });

    this.formGroupEndereco = this.fb.group({
      cli_cep: [
        null,
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
      cli_logradouro: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      cli_numero: [null, [Validators.required, Validators.maxLength(10)]],
      cli_complemento: [null, [Validators.maxLength(100)]],
      cli_bairro: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      cli_cidade: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      cli_uf: [
        null,
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      ],
    });

    this.formGroupVenda = this.fb.group({
      token_recaptcha: [null],
      fk_idcliente: [null, [Validators.required]],
      fk_idplano: [null, [Validators.required]],
      fk_idformapagamento: [null, [Validators.required]],
      fk_idcampanha: [null, [Validators.required]],
      fk_idplanoregiao: [null, [Validators.required]],

      ven_cartaocpf: [
        null,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
          this.validateBrService.cpf,
        ],
      ],
      ven_cartaonome: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],

      ven_cartaonumero: [
        null,
        [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16),
        ],
      ],
      ven_cartaocodigo: [
        null,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)],
      ],
      ven_cartaovalidade: [
        null,
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      ven_cartaobandeira: [
        "mastercard",
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
        ],
      ],

      ven_parcelas: [null, [Validators.required]],
      ven_qtddependente: [null, [Validators.required]],
      ven_total: [null, [Validators.required]],

      ven_cartaoenderecocep: [
        null,
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
      ],
      ven_cartaoenderecologradouro: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      ven_cartaoendereconumero: [
        null,
        [Validators.required, Validators.maxLength(10)],
      ],
      ven_cartaoenderecocomplemento: [null, [Validators.maxLength(100)]],
      ven_cartaoenderecobairro: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      ven_cartaoenderecocidade: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(255),
        ],
      ],
      ven_cartaoenderecouf: [
        null,
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      ],

      ven_codigoplataforma: [null],
      ven_codigocorretor: [null],
      ven_qtdparcelasdesconto: [null],
      ven_desconto: [null],
    });

    this.getByID();

    let input_group_focus = document.getElementsByClassName("form-control");
    let input_group = document.getElementsByClassName("input-group");
    for (let i = 0; i < input_group.length; i++) {
      input_group[i].children[0].addEventListener("focus", function () {
        input_group[i].classList.add("input-group-focus");
      });
      input_group[i].children[0].addEventListener("blur", function () {
        input_group[i].classList.remove("input-group-focus");
      });
    }

    setTimeout(() => {
      let pk_idcampanha = this.activatedRoute.snapshot.params.campanha;
      let pk_idplataforma = this.activatedRoute.snapshot.params.plataforma;
      let pk_idvendedor = this.activatedRoute.snapshot.params.vendedor;

      this._service.parametros_campanha.next([
        pk_idcampanha,
        pk_idplataforma,
        pk_idvendedor,
      ]);
    }, 1000);
  }

  async MostrarPopUpRegiao() {
    let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
    };

    let modal = this.modalService.open(NgbdModalRegiaoComponent, ngbModalOptions); // Render modal with modal component  

    modal.result.then((regiao) => {        
        localStorage.setItem("REGIAO_SELECIONADA", JSON.stringify(regiao))                   
        this.campanhaService.broadcastStateChange(regiao.cidade.cid_nome, false)
        if(!this.verificarPlanoRegiao()) {
          this.toastr.error('Região inválida', 'Por favor informe um plano válido para a sua região');                    
          this.MostrarPopUpRegiao()
      }
    }, (reason) => {
        // modal foi cancelado/fechado
    })
}

  async getByID() {
    try {
      this.pk_idcampanha = await +this.activatedRoute.snapshot.params.campanha;
      this.pk_idplano = await +this.activatedRoute.snapshot.params.plano;
      this.pk_idregiao = await +this.activatedRoute.snapshot.params.regiao;

      this.pk_idplanoregiao = await +this.activatedRoute.snapshot.params
        .planoregiao;

      this.cod_vendedor = await this.activatedRoute.snapshot.params.vendedor;

      this.formGroupTitular.patchValue({
        cli_cods4_corretor: this.cod_vendedor,
        fk_idcampanha: this.pk_idcampanha,
      });

      this.cod_plataforma = await this.activatedRoute.snapshot.params
        .plataforma;

      this.cpf_cliente = await this.activatedRoute.snapshot.params.cpf;

      if (this.activatedRoute.snapshot.params.idCliente)
        this.pk_idCliente_params = await this.activatedRoute.snapshot.params
          .idCliente;      

      this.formas_de_pagamento_por_regiao = [];

      if (this.pk_idplano > 0) {
        this.plano_selecionado_na_campanha = await this._service.getPlanoPorId(
          this.pk_idplano
        );

                let params = {
                    idcampanha: this.pk_idcampanha,
                    idplano: this.pk_idplano,
                    idregiao: this.pk_idregiao
                }


                this.formas_de_pagamento_por_regiao = await this._service.getByCampanhasPorPlanoRegiao(params);
                this.campanha_selecionada = await this._service.getCampanhaPorId(this.pk_idcampanha);                

                const regiaoSelecionada = JSON.parse(localStorage.getItem('REGIAO_SELECIONADA'))
                if(!regiaoSelecionada) {
                  await this.MostrarPopUpRegiao()
                } else {
                    if(!this.verificarPlanoRegiao()) {
                      this.toastr.error('Região inválida', 'Por favor informe um plano válido para a sua região');                    
                      this.TrocarDePlano()
                  }
                }

        //  console.log(this.formas_de_pagamento_por_regiao);

        if (this.cpf_cliente && this.cpf_cliente.length == 11) {
          this.formGroupTitular.patchValue({
            cli_cpf: this.cpf_cliente,
          });

          //SETA O ID DO CLIENTE QUE VEIO POR PARAMETRO
          if (this.pk_idCliente_params) {
            this.formGroupTitular.patchValue({
              pk_idcliente: this.pk_idCliente_params,
            });
          }

          this.preenchimentoAutomatico = true;
          await this.onBlurCPF();
          // Scroll to opcoes de pagamento
          setTimeout(() => {
            //this.divPagamento.nativeElement.scrollIntoView();

            this.scroll("#divPagamento", -100);
          }, 2000);
        } else {
          this.open();
        }
      }
    } catch (error) {
      console.error(error);
      this.toastr.error(
        "Error",
        "Ocorreu um erro ao resgatar informações do plano"
      );
    }
  }

  scroll(elem: string, offset: number) {
    const yOffset = offset;
    const element = document.querySelector(elem)!;

    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }

  isBoleto(forma_pagamento): boolean {
    return forma_pagamento?.fpa_nome.toLowerCase().indexOf("boleto") >= 0;
  }

  isMensal(forma_pagamento): boolean {
    return forma_pagamento?.fpa_nome.toLowerCase().indexOf("mensal") >= 0;
  }

  fpModelChangeFn(forma_pagamento) {
    console.log(forma_pagamento);

    this.mostrarFormaDePagamentoBoleto = false;
    this.mostrarFormaDePagamentoCartao = false;

    if (this.isBoleto(forma_pagamento)) {
      // Não permite que o usuário set a quantidade de parcelas
      this.mostrarPossibilidadeParcelameno = false;

      this.formGroupVenda.patchValue({
        ven_parcelas: 1,
      });

      // boleto
      this.mostrarFormaDePagamentoBoleto = true;
      this.formGroupVenda.patchValue({
        ven_cartaocpf: null,
        ven_cartaonome: null,

        ven_cartaonumero: null,
        ven_cartaocodigo: null,
        ven_cartaovalidade: null,
        ven_cartaobandeira: null,

        ven_cartaoenderecocep: null,
        ven_cartaoenderecologradouro: null,
        ven_cartaoendereconumero: null,
        ven_cartaoenderecocomplemento: null,
        ven_cartaoenderecobairro: null,
        ven_cartaoenderecocidade: null,
        ven_cartaoenderecouf: null,
      });
    } else {
      if (
        forma_pagamento.fpa_nome &&
        (forma_pagamento.fpa_nome.toLowerCase().indexOf("anual") > 0 ||
          forma_pagamento.fpa_nome.toLowerCase().indexOf("anuidade") > 0)
      ) {
        this.mostrarPossibilidadeParcelameno = true;
        this.formGroupVenda.patchValue({
          ven_parcelas: null,
        });
      } else {
        this.mostrarPossibilidadeParcelameno = false;

        this.formGroupVenda.patchValue({
          ven_parcelas: 1,
        });
      }

      this.mostrarFormaDePagamentoCartao = true;
    }
  }

  getValorMensalComDesconto(forma_pagamento) {
    return this.calcularTotalAPagar(forma_pagamento, true, false);
  }

  calcularTotalAPagar(
    forma_pagamento,
    com_desconto = true,
    valorAnual = true
  ): number {
    let total: number;
    let totalParcela: number;

    let qtdDependentes = this.dependentes ? this.dependentes.length : 0;

    let valorDependente = 0;
    let valorTitular = 0;

    if (this.dependentes?.find((dependente) => dependente.cli_titular)) {
      valorTitular = parseFloat(forma_pagamento.pre_valortitular);
      qtdDependentes--;
    } else if (this.formTitular.cli_titular.value === true) {
      valorTitular = parseFloat(forma_pagamento.pre_valortitular);
    }

    if (qtdDependentes > 0) {
      if (forma_pagamento.pre_valordependente > 0) {
        valorDependente = parseFloat(forma_pagamento.pre_valordependente);
      } else {
        valorDependente = parseFloat(forma_pagamento.pre_valortitular);
      }

      totalParcela = valorTitular + valorDependente * qtdDependentes;
    } else {
      totalParcela = valorTitular;
    }

    /** ANUAL */
    if (!this.isMensal(forma_pagamento) && valorAnual) {
      // Anual 12x
      total = totalParcela * 12;
    } else {
      // mensal
      total = totalParcela;
    }

    if (com_desconto) {
      // Aplicando desconto se houver
      if (forma_pagamento.pca_desconto && forma_pagamento.pca_desconto > 0) {
        let desconto = totalParcela * (forma_pagamento.pca_desconto / 100);

        // MULTIPLICANDO DESCONTO DE ACORDO COM A QTD DE PARCELAS DEFINIDA
        if (!this.isMensal(forma_pagamento) && valorAnual) {
          // Se for anual
          desconto = desconto * forma_pagamento.pca_parcela;
        }

        total = total - desconto;
      }

      this.formGroupVenda.patchValue({
        ven_total: total,
      });
    }

    return total;
  }

  getValorParcelaCartao(qtd_vezes): number {
    if (!this.formas_de_pagamento_selecionada) return 0;

    let total = this.calcularTotalAPagar(this.formas_de_pagamento_selecionada);

    return total / qtd_vezes;
  }

  getInteiro(value) {
    return Math.floor(value);
  }

  getDecimalPart(num) {
    let decimalStr = "00";

    try {
      if (Number.isInteger(num)) {
        return "00";
      }

      decimalStr = num.toString().split(".")[1];

            if (!decimalStr || decimalStr.length == 0) {
                decimalStr = '00'
            } else if (decimalStr && decimalStr.length == 1) {
                decimalStr += '0'
            }



        } catch {

        }

        const words = decimalStr.split('');
        return words[0] + words[1];
    }

    verificarPlanoRegiao() {
        let regiaoValida: boolean = false;
        let regiao_selecionada = localStorage.getItem("REGIAO_SELECIONADA")

        const regiao =  JSON.parse(regiao_selecionada)

        if(regiao) {
            this.campanha_selecionada.regiao_campanhas_json.forEach(o => {
                if (o.fk_idregiao == regiao.cidade.fk_idregiao) {
                    regiaoValida = true;
                }   
            });
        }
        
        return regiaoValida
    }

    verificarCepRegiao(data: any) {
        this.cepInvalidoPorRegiao = false;
        let regiao_selecionada = localStorage.getItem("REGIAO_SELECIONADA")        
             
        const regiao =  JSON.parse(regiao_selecionada)
        if(regiao) {
            const {localidade, uf} = data
            const {estado, cidade} = regiao
            
            if(estado.est_uf === uf) {
                this.cepInvalidoPorRegiao = true;
            }
        }

        return this.cepInvalidoPorRegiao
    }

  logout() {
    this.formGroupTitular.reset();
    this.formGroupEndereco.reset();
    this.pk_idtitular = null;
    this.prospeccao = null;
    this.mostrarCadastroEndereco = false;
    this.mostrarCadastroDependente = false;

    localStorage.setItem("PROSPECCAO_SELECIONADA", null);
    localStorage.setItem("CLIENTE_SELECIONADO", null);

    this.router.navigate([
      "/campanha/",
      this.pk_idcampanha,
      this.cod_plataforma,
      this.cod_vendedor,
    ]);
    // this.open();
  }

  open() {
    try {
      this.prospeccao = localStorage.getItem("PROSPECCAO_SELECIONADA");
      if (this.prospeccao) {
        this.prospeccao = JSON.parse(this.prospeccao);
        if (this.prospeccao) {
          // Set email para o titular
          this.formGroupTitular.patchValue({
            fk_idprospeccao: this.prospeccao?.pk_idprospeccao,
            cli_email: this.prospeccao?.pro_email,
          });
          return;
        }
      }
    } catch (error) {}

    this.prospeccao = null;

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };

    if (this.cpf_cliente) {
      let modal = this.modalService.open(
        NgbdModalEmailComponent,
        ngbModalOptions
      ); // Render modal with modal component
      modal.result.then(
        (result) => {
          localStorage.setItem(
            "PROSPECCAO_SELECIONADA",
            JSON.stringify(result)
          );

          this.prospeccao = result;

          // Set email para o titular
          this.formGroupTitular.patchValue({
            fk_idprospeccao: this.prospeccao?.pk_idprospeccao,
            cli_email: this.prospeccao?.pro_email,
          });
        },
        (reason) => {
          // modal foi cancelado/fechado
        }
      );
    }
  }

  openPopupDependente() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };

    let modal = this.modalService.open(
      NgbdModalDependenteComponent,
      ngbModalOptions
    ); // Render modal with modal component
    (<NgbdModalDependenteComponent>modal.componentInstance).pk_idtitular =
      this.pk_idtitular;

    this.verificarSeTemTitular();

    (<NgbdModalDependenteComponent>modal.componentInstance).temTitular =
      this.temAlgumTitularNaVenda;

    modal.result.then(
      (result) => {
        // console.log(result)
        this.dependentes.push(result);

        if (this.dependentes != null && this.dependentes.length > 0) {
          this.dependentes.forEach((d) => {
            if (d.cli_titular == true || d.cli_grauparentesco == "1") {
              this.temAlgumTitularNaVenda = true;
            }
          });
        }
      },
      (reason) => {
        // modal foi cancelado/fechado
      }
    );
  }

  openCarencia(forma_pagamento) {
    let ngbModalOptions: NgbModalOptions = {
      //backdrop: 'static',
      keyboard: true,
      size: "lg",
    };

    let modal = this.modalService.open(
      NgbdModalCarenciaComponent,
      ngbModalOptions
    ); // Render modal with modal component
    (<NgbdModalCarenciaComponent>modal.componentInstance).forma_pagamento =
      forma_pagamento;

    modal.result.then(
      (result) => {
        //
      },
      (reason) => {
        // modal foi cancelado/fechado
      }
    );
  }

  verificarSeTemTitular() {
    this.temAlgumTitularNaVenda = false;

    if (this.dependentes != null && this.dependentes.length > 0) {
      this.dependentes.forEach((d) => {
        if (d.cli_titular == true || d.cli_grauparentesco == "1") {
          this.temAlgumTitularNaVenda = true;
        }
      });
    }

    if (this.formTitular.cli_titular.value) {
      this.temAlgumTitularNaVenda = true;
    }
  }

  async onSubmitTitular() {
    try {
      this.submittedTitular = true;

      this.loading[0] = true;
      if (!this.formGroupTitular.valid) {
        this.formGroupTitular.markAllAsTouched();
        return;
      }

      let body = Object.assign({}, this.formGroupTitular.value);

      body.cli_datanascimento = this.getFormatDateIso(body.cli_datanascimento);
      body.cli_cods4_corretor = this.cod_vendedor;

      //CODIGO ANTIGO DE DENES QUE ATUALIZAVA SE O CLIENTE JA EXISTIA
      // if (!this.pk_idtitular) {
      //     let cpf = this.formGroupTitular.value.cli_cpf;

      //     if (cpf && cpf.length == 11) {

      //         try {
      //             var data = await this._service.getVendasPorCPF({
      //                 cpf: cpf
      //             });

      //             if (data) {
      //                 // GET Response
      //                 this.pk_idtitular = data.titular[0].pk_idcliente;
      //                 body.pk_idcliente = this.pk_idtitular
      //             }
      //         } catch (error) {

      //         }
      //     }
      // }

      // if (!this.pk_idtitular) {
      //     let o = await this._service.create(body);
      //     this.pk_idtitular = o.id;

      //     this.formGroupTitular.patchValue({
      //         pk_idcliente: o.id
      //     })

      //     this.toastr.success('Responsável', 'Cadastrado com sucesso');

      // } else {

      //     body.pk_idcliente = this.pk_idtitular;
      //     this.formGroupTitular.patchValue({
      //         pk_idcliente: this.pk_idtitular
      //     })
      //     await this._service.patch(body, body.pk_idcliente);
      //     this.toastr.success('Responsável', 'Alterado com sucesso');
      // }

      //CASO TENHA PASSADO POR PARAMETRO O USUÁRIO DEVERÁ SER RETORNADO
      if (
        this.activatedRoute.snapshot.params.idCliente &&
        this.activatedRoute.snapshot.params.idCliente != "0"
      ) {
        this.pk_idtitular = await this.activatedRoute.snapshot.params.idCliente;
        this.formGroupTitular.patchValue({
          pk_idcliente: this.pk_idtitular,
        });
      }

      //CENARIO EM QUE NÃO É PASSADO NADA POR PARAMETRO

      if (!this.pk_idtitular) {
        let o = await this._service.create(body);
        this.pk_idtitular = o.id;

        this.formGroupTitular.patchValue({
          pk_idcliente: o.id,
        });

        body.pk_idcliente = o.id;
        this.toastr.success("Responsável", "Cadastrado com sucesso");
      } else {
        body.pk_idcliente = this.pk_idtitular;

        this.formGroupTitular.patchValue({
          pk_idcliente: this.pk_idtitular,
        });

        let o = await this._service.patch(body, body.pk_idcliente);
        this.toastr.success("Responsável", "Alterado com sucesso");
      }

      const listaJSON = JSON.stringify(body);
      localStorage.setItem("CLIENTE_SELECIONADO", listaJSON);

      // Mostrando endereco do titular
      this.mostrarCadastroEndereco = true;

      if (body.cli_titular) {
        this.temAlgumTitularNaVenda = true;
      } else {
        this.temAlgumTitularNaVenda = false;
      }
    } catch (error) {
      console.log(error);
      this.toastr.error(
        "Error",
        "Erro interno favor entrar em contato com o suporte"
      );
    } finally {
      this.loading[0] = false;
    }
  }

  getFormatDateIso(data) {
    if (data.indexOf("-") > 0) {
      // A data ja esta no padrao iso
      return data;
    }

    var date = moment(data, "DD/MM/YYYY");
    return date.format("YYYY-MM-DD");
  }

  getDateBrFromIso(data) {
    var date = moment(data, "YYYY-MM-DD");
    return date.format("DD/MM/YYYY");
  }

  async onSubmitEndereco() {
    try {
      this.submittedEndereco = true;

      if (!this.formGroupEndereco.valid) {
        this.formGroupEndereco.markAllAsTouched();
        return;
      }

      this.loading[1] = true;

      let bodyRegiao = {
        fk_idregiao: this.pk_idregiao,
        cid_nome: this.formGroupEndereco.value.cli_cidade,
      };

      try {
        let cidade_por_regiao = await this._service.getCidadePorRegiao(
          bodyRegiao
        );

        if (cidade_por_regiao) {
          // Regiao nao permitida para essa regiao
          console.log("regiao atendida");
        }
      } catch (error) {
        this.toastr.error("Sua cidade não atende essa campanha");
        return;
      }

      let o = await this._service.patch(
        this.formGroupEndereco.value,
        this.pk_idtitular
      );

      this.toastr.success("Endereço", "Cadastrado com sucesso");

      // Mostrando dependeten para cadastro
      this.mostrarCadastroDependente = true;

      this.mostrarFormaDePagamento = true;
    } catch (error) {
      console.log(error);
      this.toastr.error(
        "Error",
        "Erro interno favor entrar em contato com o suporte"
      );
    } finally {
      this.loading[1] = false;
    }
  }

  copyLinkVenda() {
    let link: string = "https://vendas.agiben.com.br/#/checkout/";

    link += `${this.pk_idcampanha}/${this.pk_idplano}/${this.pk_idregiao}/${this.cod_plataforma}/${this.cod_vendedor}/${this.pk_idplanoregiao}/${this.formTitular.cli_cpf.value}/${this.pk_idtitular}`;

    console.log(link);

    this._clipboardService.copy(link);

    this.toastr.show(null, "Link copiado para a area de transferência");
  }

  temTitular() {
    this.temAlgumTitularNaVenda = true;
  }
  naoTemTitular() {
    let body = Object.assign({}, this.formGroupVenda.value);
    body.dependentes = this.dependentes;

    body.titular = this.formGroupTitular.value;
    body.titular.cli_titular = false;
    this.temAlgumTitularNaVenda = false;

    // console.log(body)

    if (!body.titular.cli_titular) {
      if (body.dependentes != null && body.dependentes.length > 0) {
        body.dependentes.forEach((d) => {
          if (d.cli_titular == true) {
            this.temAlgumTitularNaVenda = true;
          }
        });
      }
    }
  }

  abrirModalVisualizacaoContratoETermos() {
    const modalRef = this.modalService.open(VisualizarContratoComponent, {
      backdrop: "static",
      size: "xl",
      windowClass: "custom-modal-contract",
    });

    const { pla_contrato, pla_nome } = this.plano_selecionado_na_campanha;

    modalRef.componentInstance.pla_contrato =
      this.plano_selecionado_na_campanha.pla_contrato;
    modalRef.componentInstance.pla_nome =
      this.plano_selecionado_na_campanha.pla_nome;

    modalRef.result.then(({ token }) => {      
      this.token = token;
      this.onSubmitVenda();
    });
  }

  async onSubmitVenda() {
    try {
      this.submittedVenda = true;
      this.loading[7] = true;

      if (!this.token) {
        this.recaptchaTouched = true;
        return;
      }
      // console.debug(`Token [${this.token}] generated`);

      if (!this.formas_de_pagamento_selecionada) {
        this.toastr.error("Error", "Informe uma forma de pagamento.");
        return;
      }

      await this.buscarCEPComercial()

      if(!this.cepInvalidoPorRegiao) {
        return
      }

      this.recaptchaTouched = false;

      this.formGroupVenda.patchValue({
        token_recaptcha: this.token,
        fk_idplano: this.pk_idplano,
        fk_idcliente: this.pk_idtitular,
        fk_idformapagamento:
          this.formas_de_pagamento_selecionada?.fk_idformapagamento,
        ven_qtddependente: this.dependentes.length,
        ven_total: this.calcularTotalAPagar(
          this.formas_de_pagamento_selecionada
        ),
        fk_idcampanha: this.pk_idcampanha,
        //fk_idplanoregiao: this.pk_idplanoregiao,
        fk_idplanoregiao:
          this.formas_de_pagamento_selecionada?.pk_idplanoregiao,
        ven_codigoplataforma: this.cod_plataforma,
        ven_codigocorretor: this.cod_vendedor,
        ven_qtdparcelasdesconto:
          this.formas_de_pagamento_selecionada?.pca_parcela,
        ven_desconto: this.formas_de_pagamento_selecionada?.pca_desconto,
      });

      if (
        !this.isBoleto(this.formas_de_pagamento_selecionada) &&
        !this.formGroupVenda.valid
      ) {
        this.formGroupVenda.markAllAsTouched();
        return;
      }

      let body = Object.assign({}, this.formGroupVenda.value);

      body.dependentes = this.dependentes;
      body.titular = this.formGroupTitular.value;

      // Caso o responsável financeiro não seja titular e o no cadastro de dependente não tiver nenhum TITULAR o sistema deverá informar a exception.

      if (!this.verificarSeBodyTemTitular(body)) {
        // O sistema deverá sempre solicitar um TITULAR para evitar que a venda saia zerada:
        this.toastr.error("Aviso", "Informe o titular do plano");
        return;
      }

      const contAtivos = this.verificarQtdTitular(body);
      if (contAtivos > 1) {
        this.toastr.error(
          "Aviso",
          "Informe apenas UM titular ao seu plano. O responsável financeiro ou apenas um dependente."
        );
        return;
      } else if (!contAtivos) {
        this.toastr.error(
          "Error",
          "Informe ao menos uma pessoa como titular do plano."
        );
      }

      body.titular.cli_datanascimento = this.getFormatDateIso(
        body.titular.cli_datanascimento
      );
      body.enderecoTitular = this.formGroupEndereco.value;
      body.forma_pagamento = this.formas_de_pagamento_selecionada;
      body.campanha = this.campanha_selecionada;
      body.plano = this.plano_selecionado_na_campanha;

      body.cod_vendedor = this.cod_vendedor;
      body.cod_plataforma = this.cod_plataforma;

      if (body.plano) {
        delete body.plano.plano_regioes_json;
      }

      if (body.campanha) {
        delete body.campanha.conta_campanhas_json;
        delete body.campanha.plano_regioes_json;
        delete body.campanha.plano_campanhas_json;
        delete body.campanha.regiao_campanhas_json;
      }

      if (!body.pk_idvenda) {
        let o = await this._service.createVenda(body);
        body.pk_idvenda = o.id;
        body.ven_protocolo = o.ven_protocolo;
        body.ven_urlboleto = o.ven_urlboleto;
        body.dataVencimento = o.ven_vencimento;
        //this.toastr.success('Cartão', 'Cadastrado com sucesso');

        this.formGroupVenda.patchValue({
          pk_idvenda: o.id,
          ven_protocolo: o.ven_protocolo,
        });
      } else {
        let o = await this._service.putVenda(
          body,
          this.formGroupVenda.value.pk_idvenda
        );
        body.ven_protocolo = o.ven_protocolo;
        body.ven_urlboleto = o.ven_urlboleto;
        body.dataVencimento = o.ven_vencimento;

        this.formGroupVenda.patchValue({
          ven_protocolo: o.ven_protocolo,
        });
        //this.toastr.success('Cartão', 'Alterado com sucesso');
      }

      this._service.saveStorageLocal("VENDA_OK", body);

      //TODO
      //this.router.navigate(['/confirmacao', this.pk_idcampanha, this.pk_idplano, this.pk_idregiao, body.cod_plataforma, body.cod_vendedor, body.pk_idvenda]);
      let venda_id = btoa(body.pk_idvenda); // this will encode the data
      this.router.navigate(["/confirmacao", venda_id]);
    } catch (error) {
      if (error.status == 404) {
        if (error?.error?.code === 1015) {
          this.toastr.error(
            "Error",
            "Código da plataforma informado é inválido."
          );
        }

        if (error?.error?.code === 1016) {
          this.toastr.error(
            "Error",
            "Código do produto não cadastrado para a empresa."
          );
        }

        if (error?.error?.code === 1017) {
          this.toastr.error(
            "Error",
            "Código do vendedor informado é inválido."
          );
        }
        if (error?.error?.code === 1018) {
          this.toastr.error("Error", "Código do produto não informado.");
        }

        if (error?.error?.code === 1019) {
          this.toastr.error("Error", "Erro na validação dos dados");
        }
      } else if (error.status === 418) {
        this.toastr.error("Error", error?.error?.message);
      } else {
        this.toastr.error(
          "Error",
          "Erro interno favor entrar em contato com o suporte"
        );
      }

      console.log(error);
    } finally {
      this.loading[7] = false;
    }
  }

  async onClickDeleteDependente(obj) {
    try {
      this.loading[5] = true;

      let o = this._service.delete(obj.pk_idcliente);
      console.log(o);

      this.toastr.success("Dependente", "Dependente deletado com sucesso");

      //Removing the element by id
      this.dependentes = this.dependentes.filter(
        (item) => item.pk_idcliente !== obj.pk_idcliente
      );

      if (this.dependentes != null && this.dependentes.length > 0) {
        this.dependentes.forEach((d) => {
          if (d.cli_titular == true) {
            this.temAlgumTitularNaVenda = true;
          }
        });
      } else {
        let body = Object.assign({}, this.formGroupTitular.value);
        if (body.cli_titular) {
          this.temAlgumTitularNaVenda = true;
        } else {
          this.temAlgumTitularNaVenda = false;
        }
      }
    } catch (error) {
      console.log(error);
      this.toastr.error(
        "Error",
        "Erro interno favor entrar em contato com o suporte"
      );
    } finally {
      this.loading[5] = false;
    }
  }

  async viewDependente(obj) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    };

    let modal = this.modalService.open(
      NgbdModalDependenteComponent,
      ngbModalOptions
    ); // Render modal with modal component

    let dependenteAtual = Object.assign({}, obj);

    this.verificarSeTemTitular();

    (<NgbdModalDependenteComponent>modal.componentInstance).setDependente(
      dependenteAtual,
      this.temAlgumTitularNaVenda
    );

    modal.result.then(
      (result) => {
        console.log(result);

        if (result) {
          // update dependente at list
          for (let index = 0; index < this.dependentes.length; index++) {
            const d = this.dependentes[index];
            if (d.pk_idcliente == result.pk_idcliente) {
              this.dependentes[index] = result;

              if (this.dependentes != null && this.dependentes.length > 0) {
                this.dependentes.forEach((d) => {
                  if (d.cli_titular == true || d.cli_grauparentesco == "1") {
                    this.temAlgumTitularNaVenda = true;
                  }
                });
              }
            }
          }
        }
      },
      (reason) => {
        // modal foi cancelado/fechado
      }
    );
  }

    async buscarCEPComercial() {

        try {

            this.readOnlyEnderecoTitular = false

            let cep = this.formGroupEndereco.value.cli_cep

            if (!cep || cep.length == 0)
                return;

      let numbersOnly = cep.match(/\d/g).join("");

      if (numbersOnly.length != 8) return;

      this.loading[2] = true;

            let data = await this._service.buscarCEP(numbersOnly);            

            this.loading[2] = false;

           
      if (!data) return;

      if(!this.verificarCepRegiao(data)) {

        this.readOnlyEnderecoTitular = true
        this.toastr.error('Cep inválido', 'Por favor informe um cep dentro da região selecionada');
        
        this.formGroupEndereco.patchValue(
            {
                cli_logradouro: "",
                cli_bairro: "",
                cli_cidade: "",
                cli_numero: "",
                cli_complemento: "",
                cli_uf: "",
            }
        );
        
        return

      }

      if (data.erro) {
        this.toastr.info(null, "CEP não foi encontrado");
        this.readOnlyEnderecoTitular = true;

        //LIMPA FORM
        this.formGroupEndereco.patchValue({
          cli_logradouro: "",
          cli_bairro: "",
          cli_cidade: "",
          cli_numero: "",
          cli_complemento: "",
          cli_uf: "",
        });

        return;
      }

      this.formGroupEndereco.patchValue({
        cli_logradouro: data.logradouro,
        cli_bairro: data.bairro,
        cli_cidade: data.localidade,
        cli_uf: data.uf,
      });

      if (data.logradouro == "") {
        this.readOnlyEnderecoTitular = false;
      } else {
        this.readOnlyEnderecoTitular = true;
      }
    } catch (error) {
      this.loading[2] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.toastr.error(
          "Error",
          "Erro interno favor entrar em contato com o suporte"
        );
      }
    }
  }

  async buscarCEPVenda() {
    try {
      this.readOnlyEndereco = false;

      let cep = this.formGroupVenda.value.ven_cartaoenderecocep;

      if (!cep || cep.length == 0) return;

      let numbersOnly = cep.match(/\d/g).join("");

      if (numbersOnly.length != 8) return;

      this.loading[6] = true;

      let data = await this._service.buscarCEP(numbersOnly);

      this.loading[6] = false;

      if (!data) return;

      if (data.erro) {
        this.toastr.info(null, "CEP não foi encontrado");
        this.readOnlyEndereco = true;

        //LIMPA FORM
        this.formGroupEndereco.patchValue({
          ven_cartaoenderecologradouro: "",
          ven_cartaoenderecobairro: "",
          ven_cartaoenderecocidade: "",
          ven_cartaoendereconumero: "",
          ven_cartaoenderecocomplemento: "",
          ven_cartaoenderecouf: "",
        });

        return;
      }

      this.formGroupVenda.patchValue({
        ven_cartaoenderecologradouro: data.logradouro,
        ven_cartaoenderecobairro: data.bairro,
        ven_cartaoenderecocidade: data.localidade,
        ven_cartaoenderecouf: data.uf,
      });

      if (data.logradouro == "") {
        this.readOnlyEndereco = false;
      } else {
        this.readOnlyEndereco = true;
      }
    } catch (error) {
      this.loading[6] = false;

      if (error && error.status != 401 && error.status != 404) {
        this.toastr.error(
          "Error",
          "Erro interno favor entrar em contato com o suporte"
        );
      }
    }
  }

  async onBlurCPF() {
    try {
      let cpf = this.formGroupTitular.value.cli_cpf;

      if (!cpf || cpf.length < 11) return;

      this.toggleElementBlocking();

      var data = await this._service.getVendasPorCPF({
        cpf: cpf,
        idCliente: this.pk_idCliente_params,
      });

      if (!data) return;

      if (data && data.titular) {
        const listaJSON = JSON.stringify(data.titular[0]);
        localStorage.setItem("CLIENTE_SELECIONADO", listaJSON);
      }

      // GET Response
      let titular = data.titular[0];

      if (data.dependentes) {
        this.dependentes = data.dependentes;
        if (this.dependentes != null && this.dependentes.length > 0) {
          this.dependentes.forEach((d) => {
            if (d.cli_titular == true) {
              this.temAlgumTitularNaVenda = true;
            }
          });
        }
      }

      if (titular.cli_titular) {
        this.temAlgumTitularNaVenda = true;
      }

      this.pk_idtitular = titular.pk_idcliente;

      if (titular.cli_datanascimento) {
        let datanascimento = titular.cli_datanascimento.substring(0, 10);
        titular.cli_datanascimento = this.getDateBrFromIso(datanascimento);
      }

      this.formGroupTitular.patchValue(titular);

      this.formGroupEndereco.patchValue(titular);

      this.mostrarCadastroEndereco = true;
      this.mostrarCadastroDependente = true;
      this.mostrarFormaDePagamento = true;

      console.log(this.dependentes);
    } catch (error) {
    } finally {
      this.stopToggleElementBlocking();
    }
  }

  delayBlockUI;
  toggleElementBlocking() {
    this.stopToggleElementBlocking();

    this.delayBlockUI = setTimeout(() => {
      this.blockUIElement.start();
    }, 1000);
  }

  stopToggleElementBlocking() {
    if (this.delayBlockUI) {
      clearTimeout(this.delayBlockUI);
      this.blockUIElement.stop();
      this.delayBlockUI = null;
      return;
    }
  }

  async TrocarDePlano() {
    this.router.navigate([
      "/campanha/",
      this.pk_idcampanha,
      this.cod_plataforma,
      this.cod_vendedor,
    ]);
  }

  getDescricaoGrau(obj) {
    switch (obj) {
      case "1":
        return "TITULAR";
      case "3":
        return "CONJUGE/COMPANHEIRO";
      case "4":
        return "FILHO";
      case "6":
        return "ENTEADO";
      case "8":
        return "PAI/MAE";
      case "10":
        return "AGREGADOS/OUTROS";
      default:
        return "NÃO INFORMADO";
    }
  }

  verificarSeBodyTemTitular(body: any): boolean {
    let temTitular = true;
    if (!body.titular.cli_titular) {
      temTitular = false;
      if (body.dependentes != null && body.dependentes.length > 0) {
        body.dependentes.forEach((d) => {
          if (d.cli_titular == true || d.cli_grauparentesco == "1") {
            temTitular = true;
            this.temAlgumTitularNaVenda = true;
          }
        });
      }
    }
    return temTitular;
  }

  verificarQtdTitular(body: any): number {
    let contAtivos = 0;
    if (body.titular.cli_titular) {
      contAtivos++;
      if (body.dependentes != null && body.dependentes.length > 0) {
        body.dependentes.forEach((d) => {
          if (d.cli_titular == true || d.cli_grauparentesco == "1") {
            contAtivos++;
          }
        });
      }
    } else {
      if (body.dependentes != null && body.dependentes.length > 0) {
        body.dependentes.forEach((d) => {
          if (d.cli_titular == true || d.cli_grauparentesco == "1") {
            contAtivos++;
          }
        });
      }
    }
    return contAtivos;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';

@Injectable({
    providedIn: 'root'
})
export class BoletoService {

    constructor(private _httpClient: HttpClient, private _appService: AppService) { }

    async create(params: any): Promise<any> {

        var retorno = null;

        // try {
        //     const urlAPI = `${this._appService.getUrlAPI()}prospeccao`

        //     retorno = await this._httpClient.post<any>(urlAPI, params).toPromise();
        // } catch (error) {
        //     console.log(error)
        //     throw error
        // }

        return retorno;
    }


}




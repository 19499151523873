import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-youtube-component',
    styleUrls: ['./youtube.component.scss'],
    templateUrl: 'youtube.component.html'
})
export class NgbdModalYoutubeComponent implements OnInit {

    safeSrc: SafeResourceUrl;

    @Input() url;
    @Input() titulo;

    constructor(private sanitizer: DomSanitizer,  public activeModal: NgbActiveModal) {

    }

    ngOnInit(): void {
        this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsCampanha } from './components/campanha/campanha.component';
import { ComponentsComponent } from './components/components.component';
import { ComponentsCheckout } from './components/checkout/checkout.component';

import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { ComponentsConfirmacao } from './components/confirmacao/confirmacao.component';
import { ComponentsProcessando } from './components/processando/processando.component';


const routes: Routes = [
  { path: '', component: SignupComponent  },
  { path: 'home', component: ComponentsComponent },
  { path: 'campanha/:campanha/:plataforma/:vendedor', component: ComponentsCampanha },
  // { path: 'checkout/:campanha/:plano/:regiao/:plataforma/:vendedor/:planoregiao/:cpf', component: ComponentsCheckout },
  { path: 'checkout/:campanha/:plano/:regiao/:plataforma/:vendedor/:planoregiao/:cpf/:idCliente', component: ComponentsCheckout },
  { path: 'confirmacao/:campanha/:plano/:regiao/:plataforma/:vendedor/:venda', component: ComponentsConfirmacao },
  { path: 'confirmacao/:id', component: ComponentsConfirmacao },
  { path: 'processando/:id', component: ComponentsProcessando },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'nucleoicons', component: NucleoiconsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      'x-access-token': this.accessToken
    }
  }

  getHeadersNest() {

  }

  getUrlAPI() {
    //return 'http://localhost/v1/'
    //return 'https://api.agiben.com.br/v1/'
    return environment.API_URL;
  }

  getUrlAPINest() {
    //return 'http://localhost/v1/'
    //return 'https://api.agiben.com.br/v1/'
    return environment.API_URL_NEST;

  }

}
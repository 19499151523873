import { PlatformLocation } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal, NgbModalOptions, NgbNavbar } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from 'app/shared/navbar/navbar.component';
import { ToastrService } from 'ngx-toastr';
import { NgbdModalCampanhaPausadaServiceComponent } from '../modal/campanhaPausada/campanhaPausada.component';
import { NgbdModalMaisInformacoesComponent } from '../modal/maisInformacoes/maisInformacoes.component';
import { NgbdModalRegiaoComponent } from '../modal/regiao/regiao.component';
import { NgbdModalSemPlanoRegiaoComponent } from '../modal/SemPlanoRegiao/SemPlanoRegiao.component';
import { NgbdModalYoutubeComponent } from '../modal/youtube/youtube.component';
import { CampanhaService } from './campanha.service';

@Component({
    selector: 'app-components',
    templateUrl: 'campanha.component.html',
    // styles: [`
    // ngb-progressbar {
    //     margin-top: 5rem;
    // }
    // `,
    // './campanha.component.scss']

    styleUrls: ['./campanha.component.scss']
})

export class ComponentsCampanha implements OnInit, OnDestroy {
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: { year: number, month: number };
    model: NgbDateStruct;
    campanhaAtual: any = {}
    plano_campanhas_json
    img
    regiao_selecionada
    cidade_seleciaonda = ''
    activatedRoute: ActivatedRoute;
    isOpened = false
    temPlanoNaRegiaoSelecionada = false

    constructor(private renderer: Renderer2,
        private toastr: ToastrService,
        private platformLocation: PlatformLocation,
        private router: Router, private modalService: NgbModal, public _service: CampanhaService, private element: ElementRef,
        route: ActivatedRoute) {

        this.activatedRoute = route;

    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll()
    }

    async ngOnInit() {

        

        this._service.name.subscribe(regiao_selecionada => {

            if (regiao_selecionada == null) {
                this.regiao_selecionada = null
                this.plano_campanhas_json = null
                this.cidade_seleciaonda = null
                this.open();
            }
        })


        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function () {
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function () {
                input_group[i].classList.remove('input-group-focus');
            });
        }

        await this.getCampanha()

        setTimeout(() => {

            if (this.cidade_seleciaonda == "") {
                this.open()
            }

            let pk_idcampanha = this.activatedRoute.snapshot.params.campanha;
            let pk_idplataforma = this.activatedRoute.snapshot.params.plataforma;
            let pk_idvendedor = this.activatedRoute.snapshot.params.vendedor;

            this._service.parametros_campanha.next([pk_idcampanha, pk_idplataforma, pk_idvendedor])

        }, 1000);


        const hoje = new Date();
        const dataFimDampanha = new Date(this.campanhaAtual.cam_termino)

        // this.campanhaAtual.cam_status === 'I' ) || 
        //Valida se a campanha está ativa


        if ((this.campanhaAtual.cam_status === 'I') || (hoje.getTime() > dataFimDampanha.getTime())) {


            let ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            };

            let modal = this.modalService.open(NgbdModalCampanhaPausadaServiceComponent, ngbModalOptions); // Render modal with modal component  
            (<NgbdModalCampanhaPausadaServiceComponent>modal.componentInstance).setNomeCampanha(this.campanhaAtual.cam_nome);


            modal.result.then((result) => {

                // localStorage.setItem("PROSPECCAO_SELECIONADA", JSON.stringify(result))

                // this.prospeccao = result

                // // Set email para o titular 
                // this.formGroupTitular.patchValue({
                //     fk_idprospeccao: this.prospeccao?.pk_idprospeccao,
                //     cli_email: this.prospeccao?.pro_email
                // })

            }, (reason) => {
                // modal foi cancelado/fechado
            })



            // let pk_idplataforma = this.activatedRoute.snapshot.params.plataforma;
            // let pk_idvendedor = this.activatedRoute.snapshot.params.vendedor;
            // //  this.router.navigate(['/campanha', 561, pk_idplataforma, pk_idvendedor]);

            // let url = 'https://vendas.agiben.com.br/#/campanha/' + 561 + '/' + pk_idplataforma + '/' + pk_idvendedor
            // window.open(url, '_self');

            // window.location.reload();

        }

        //LIMPAR TODAS AS CACHES
        localStorage.setItem("CLIENTE_SELECIONADO", null)        
        localStorage.setItem("VENDA_OK", null)
        localStorage.setItem("PROSPECCAO_SELECIONADA", null)
        

    }

    open() {

        try {
            let regiao_selecionada = localStorage.getItem("REGIAO_SELECIONADA")
            if (regiao_selecionada) {
                regiao_selecionada = JSON.parse(regiao_selecionada)
                if (regiao_selecionada) {
                    this.setRegiao(regiao_selecionada)
                    return
                }
            }
        } catch (error) {

        }

        if (this.isOpened || this.modalService.hasOpenModals())
            return

        this.MostrarPopUpRegiao()
    }

    async setRegiao(regiao) {

        //Chaa 
        this.temPlanoNaRegiaoSelecionada = false;
        if (regiao != null && this.campanhaAtual.cam_status === 'A') {

            this.cidade_seleciaonda = regiao.cidade.cid_nome;
            this.regiao_selecionada = regiao

            this.campanhaAtual.regiao_campanhas_json.forEach(o => {

                if (o.fk_idregiao == regiao.cidade.fk_idregiao) {
                    // Tem regiao selecionada no plano
                    console.log('Tem regiao selecionada no plano')
                    this.temPlanoNaRegiaoSelecionada = true
                    this._service.broadcastStateChange(regiao.cidade.cid_nome, true)
                }

            });

            if (!this.temPlanoNaRegiaoSelecionada) {
                // Plano selecionado não tem na regiao selecionada
                //     this.toastr.info('Aviso', 'A campanha selecionada não atende sua região. Entre e contato com o seu corretor de vendas.');

                this._service.broadcastStateChange(regiao.cidade.cid_nome, false)

                let ngbModalOptions: NgbModalOptions = {
                    backdrop: 'static',
                    keyboard: false,
                    size: 'lg'
                };

                let modal = this.modalService.open(NgbdModalSemPlanoRegiaoComponent, ngbModalOptions); // Render modal with modal component  
                (<NgbdModalSemPlanoRegiaoComponent>modal.componentInstance).cod_corretor = this.activatedRoute.snapshot.params.vendedor;

                modal.result.then((result) => {
                    // this.isOpened = false
                    // this.setRegiao(result)
                    this.MostrarPopUpRegiao()

                }, (reason) => {
                    // modal foi cancelado/fechado
                    // this.isOpened = false
                })

            } else {


                let planos = this.campanhaAtual.plano_campanhas_json.sort(this.dynamicSort("pca_ordem"))
                // Tem regiao selecionada no plano
                this._service.broadcastStateChange(regiao.cidade.cid_nome, true)


                this.plano_campanhas_json = []
                planos.forEach(o => {

                    //this.plano_campanhas_json.push
                    if (o.pca_ordem > 0) {

                        // Procurando de acordo com a regiao selecionada e a forma de pagamento
                        o.regiao_plano_json.forEach(regiao_plano => {


                            if (regiao_plano.fk_idregiao === this.regiao_selecionada.cidade.fk_idregiao &&
                                regiao_plano.fk_idformapagamento === o.fk_idformapagamento) {

                                this.plano_campanhas_json.push({
                                    fk_idplanoregiao: regiao_plano.pk_idplanoregiao,
                                    fk_idplano: o.fk_idplano,
                                    fk_idregiao: regiao_plano.fk_idregiao,
                                    pla_nome: o.pla_nome,
                                    pla_procedimentos: o.pla_procedimentos,
                                    fpa_nome: o.fpa_nome,
                                    pre_valortitular: regiao_plano.pre_valortitular,
                                    fk_idempresa: regiao_plano.fk_idempresa,
                                })


                            }

                        });

                        //
                        //o.regiao_plano_json
                    }
                });

            }
        }



    }


    VisualizarMaisInformacoes(obj) {

        if (obj && obj.fk_idplano) {

            let ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false,
                size: 'md'
            };

            let modal = this.modalService.open(NgbdModalMaisInformacoesComponent, ngbModalOptions); // Render modal with modal component  
            (<NgbdModalMaisInformacoesComponent>modal.componentInstance).cod_plano = obj.fk_idplano;


        }

    }

    isClin(obj) {


        if (obj.fk_idempresa === 384) {
            return true;

        } else
            return false;


    }

    getValorDoPlanoPorRegiao(plano) {

        let valorPlanoPorRegiao = 'CONSULTAR PREÇO'

        
        plano.regiao_plano_json.forEach(o => {

            if (o.fk_idregiao == this.regiao_selecionada.cidade.fk_idregiao) {
                // Regiao encontrada 
                valorPlanoPorRegiao = o.pre_valortitular
            }

        });

        return valorPlanoPorRegiao

    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getInteiro(value) {
        return Math.floor(value);
    }

    getDecimalPart(num) {
        let decimalStr = '00'

        try {

            if (Number.isInteger(num)) {
                return '00';
            }

            decimalStr = num.toString().split('.')[1];

            if (!decimalStr || decimalStr.length == 0) {
                decimalStr = '00'
            } else if (decimalStr && decimalStr.length == 1) {
                decimalStr += '0'
            }

        } catch {

        }

        return decimalStr;
    }

    getPeriodo(plano) {

        let retorno = 'Mensal'

        if (plano.fpa_nome && (plano.fpa_nome.toLowerCase().indexOf('anual') > 0 || plano.fpa_nome.toLowerCase().indexOf('anuidade') > 0)) {
            retorno = 'Anual'
        }

        return retorno;
    }

    async getCampanha() {
        try {


            let pk_idcampanha = await +this.activatedRoute.snapshot.params.campanha;

            let id = pk_idcampanha;


            if ((parseInt(this.activatedRoute.snapshot.queryParams["c"], 10)) > 0) {
                id = parseInt(this.activatedRoute.snapshot.queryParams["c"], 10)
            }


            var data = await this._service.getByID(id);
            if (!data)
                return;
            // GET Response



            let o = data[0];


            await this.load(o)
            let imagePath = `/campanhas/${o.pk_idcampanha}.jpg`
            this.img = this._service.getUrlImage(imagePath, true)
            this.campanhaAtual = o
        } catch (error) {
            if (error) {
                if (error.status == 404) {
                    //this.apiService.showToast(null, "campanha.edit.404", 'error');
                }
                else if (error.status != 401) {
                    //this.apiService.errorMessageShow(error);
                }
            }
        }
    }

    async load(o: any) {

    }


    async MostrarPopUpRegiao() {
        this.isOpened = true

        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };

        let modal = this.modalService.open(NgbdModalRegiaoComponent, ngbModalOptions); // Render modal with modal component  

        modal.result.then((result) => {
            this.isOpened = false

            localStorage.setItem("REGIAO_SELECIONADA", JSON.stringify(result))
            
            this.setRegiao(result)
        }, (reason) => {
            // modal foi cancelado/fechado
            this.isOpened = false
        })
    }

    async MostrarPopUpYoutube() {
        this.isOpened = true

        let ngbModalOptions: NgbModalOptions = {
            //backdrop: 'static',
            //keyboard: false,
            size: 'lg',
            windowClass: 'youtube-class'
        };

        let modal = this.modalService.open(NgbdModalYoutubeComponent, ngbModalOptions); // Render modal with modal component  

        (<NgbdModalYoutubeComponent>modal.componentInstance).url = this.campanhaAtual.emp_linkvideo;
        (<NgbdModalYoutubeComponent>modal.componentInstance).titulo = this.campanhaAtual.cam_nome;


        modal.result.then((result) => {

        }, (reason) => {
            // modal foi cancelado/fechado
        })
    }


    async comprar(plano) {



        let pk_idcampanha = await this.activatedRoute.snapshot.params.campanha;
        let pk_idplataforma = await this.activatedRoute.snapshot.params.plataforma;
        let pk_idvendedor = await this.activatedRoute.snapshot.params.vendedor;

        let pk_idplano = plano.fk_idplano;
        let pk_idregiao = this.regiao_selecionada.cidade.fk_idregiao;

        let fk_idplanoregiao = plano.fk_idplanoregiao;

        // localStorage.setItem("PROSPECCAO_SELECIONADA", null)        
        
        let cliente_compra = localStorage.getItem("CLIENTE_SELECIONADO")
        if (cliente_compra != "null") {
            let cliente_compra_json = await JSON.parse(cliente_compra)
            if (cliente_compra_json) {
                
                this.router.navigate(['/checkout', pk_idcampanha, pk_idplano, pk_idregiao, pk_idplataforma, pk_idvendedor, fk_idplanoregiao, cliente_compra_json.cli_cpf, cliente_compra_json.pk_idcliente]);
            }
        } else {
            this.router.navigate(['/checkout', pk_idcampanha, pk_idplano, pk_idregiao, pk_idplataforma, pk_idvendedor, fk_idplanoregiao, 0, 0]);
        }

    }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpEvent,
} from "@angular/common/http";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: "root",
})
export class CampanhaService {
  name: Subject<string> = new Subject();
  mostrarMenu: Subject<boolean> = new Subject();
  parametros_campanha: Subject<string[]> = new Subject();

  urlAPI;
  constructor(
    private _httpClient: HttpClient,
    private _appService: AppService
  ) {
    this.urlAPI = this._appService.getUrlAPINest() + 'inclusao-pf/';
  }

  async getByID(id: number): Promise<any> {    
    var retorno = null;

    try {
      retorno = await this._httpClient
        .get<any>(this.urlAPI + `campanhas/${id}`)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  /**
   * S3 - Get link file
   * @param filePath
   * @param refreshImage
   * @returns
   */
  public getUrlImage(filePath, forceRefreshImage: boolean = false) {
    // let ret = 'https://clinfluencer-imagens.s3.amazonaws.com' + filePath
    let ret =
      "https://dest-clinfluencer-imagens.s3.us-east-2.amazonaws.com" + filePath;

    if (forceRefreshImage) {
      ret += "?" + new Date().getTime();
    }

    return ret;
  }

  broadcastStateChange(text: string, mostrarMenu: boolean) {
    this.name.next(text);
    this.mostrarMenu.next(mostrarMenu);
  }

  async getByCampanhasPorPlanoRegiao(params: any): Promise<any> {
    var retorno = null;

    try {
      retorno = await this._httpClient
        .post<any>(
          this.urlAPI + `campanhas/filter/campanhas_por_plano_regiao`,
          params
        )
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }
}

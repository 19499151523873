import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CampanhaPausadaService } from './campanhaPausada.service';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-campanhaPausada-component',
  templateUrl: './campanhaPausada.component.html',
  styleUrls: ['./campanhaPausada.component.scss']
})
export class NgbdModalCampanhaPausadaServiceComponent implements OnInit {

  state: any = {
    listEstados: null,
    pagingEstados: null,

  

    listCidades: null,
    pagingCidades: null,

    loading: [false, false, false, false],
    is404: [false, false, false, false],

    disabled: false,
  }
  numberOfItemsFromEndBeforeFetchingMore = 5;
  typeaheadEstados = new EventEmitter<string>();
  typeaheadCidades = new EventEmitter<string>();
  submitted = false;
  nomeCampanha = '';

  public form: FormGroup;

  get f() {
    return this.form.controls;
  }

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal,
    public _service: CampanhaPausadaService, private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      estado: [null, [Validators.required]],
      cidade: [null, [Validators.required]]
    });

    // https://embed.plnkr.co/plunk/jCDw5L
    this.typeaheadEstados
      .pipe(
        debounceTime(500),
        switchMap(term => this.getEstados(term))
      )
      .subscribe(items => {
        this.cd.markForCheck();/**/
      }, (err) => {
        this.cd.markForCheck();/**/
      });

    // https://embed.plnkr.co/plunk/jCDw5L
    this.typeaheadCidades
      .pipe(
        debounceTime(500),
        switchMap(term => this.getCidades(term))
      )
      .subscribe(items => {
        this.cd.markForCheck();/**/
      }, (err) => {
        this.cd.markForCheck();/**/
      });

    this.getEstados()

    this.f.estado.valueChanges.subscribe((data) => {
      setTimeout(() => {
        this.getCidades()
      }, 1);
    })

  }


  setNomeCampanha(obj: any) {
    
     this.nomeCampanha = obj;    
}

  //#region Estados
  async getEstados(searchTerm: string = null, offset: number = 0) {

    try {

      this.state.is404[4] = false;
      this.state.loading[4] = true;

      let params = {
        searchQuery: searchTerm,
        status: 'A',
        max_limit: 50,
        offset: offset,
        sortBy: 'est_nome',
        sortDesc: 'ASC'
      }

      let data = await this._service.getEstados(params);

      this.state.loading[4] = false;

      this.state.pagingEstados = {
        total: data[0].full_count
      }

      if (offset == 0) {
        this.state.estados = data
      } else {
        // Add more row in list
        this.state.estados = this.state.estados.concat(data)
      }

    } catch (error) {

      this.state.estados = null;

      this.state.loading[4] = false;

      if (error && error.status != 401 && error.status != 404) {
        //this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[4] = error.status == 404;

    }

  }

  async onClearEstados() {

    // reset
    this.form.patchValue({
      estado: null,
      cidade: null
    });

    this.state.cidades = null

    this.getEstados();
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollEstado({ end }, searchTerm) {
    if (this.state.estados && end + this.numberOfItemsFromEndBeforeFetchingMore >= this.state.estados.length) {
      this.onScrollToEndEstado(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndEstado(searchTerm) {

    if (this.state.loading[4] ||
      !this.state.estados ||
      !this.state.pagingEstados ||
      this.state.estados.length >= this.state.pagingEstados.total) {
      return;
    }

    var currentOffset = this.state.estados.length;

    await this.getEstados(searchTerm, currentOffset);/**/

  }


  //#endregion


  //#region Cidades
  async getCidades(searchTerm: string = null, offset: number = 0) {

    try {
      this.state.is404[3] = false;
      this.state.loading[3] = true;

      let params = {
        searchQuery: searchTerm,
        idestado: this.form.value.estado.pk_idestado,
        idregiao: 0,
        status: 'A',
        max_limit: 50,
        offset: offset,
        sortBy: 'cid_nome',
        sortDesc: 'ASC'
      }

      let data = await this._service.getCidades(params);

      this.state.loading[3] = false;

      this.state.pagingCidades = {
        total: data[0].full_count
      }

      if (offset == 0) {
        this.state.cidades = data
      } else {
        // Add more row in list
        this.state.cidades = this.state.cidades.concat(data)
      }

    } catch (error) {

      this.state.cidades = null;

      this.state.loading[3] = false;

      if (error && error.status != 401 && error.status != 404) {
        //this.apiService.errorMessageShow(error);
      }

      // Nenhum registro foi cadastrado
      this.state.is404[3] = error.status == 404;

    }

  }

  async onClearCidades() {

    // reset
    this.form.patchValue({
      cidade: null
    });

    this.getCidades();
  }

  // https://stackblitz.com/run?file=src%2Fvirtual-scroll-example.component.ts
  onScrollCidade({ end }, searchTerm) {
    if (this.state.cidades && end + this.numberOfItemsFromEndBeforeFetchingMore >= this.state.cidades.length) {
      this.onScrollToEndCidade(searchTerm);
    }
  }

  // https://stackblitz.com/edit/ng-select-infinite
  // https://github.com/ng-select/ng-select/issues/683
  // https://github.com/ng-select/ng-select/issues/1596#issuecomment-619442786
  async onScrollToEndCidade(searchTerm) {

    if (this.state.loading[3] ||
      !this.state.cidades ||
      !this.state.pagingCidades ||
      this.state.cidades.length >= this.state.pagingCidades.total) {
      return;
    }

    var currentOffset = this.state.cidades.length;

    await this.getCidades(searchTerm, currentOffset);/**/

  }


  //#endregion

  async onSubmit() {

    if (!this.form.valid) {
      this.form.markAllAsTouched()
      return
    }
    this.activeModal.close(this.form.value)
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';


import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { HttpClientModule } from '@angular/common/http';

import { NgSelectModule } from '@ng-select/ng-select';

import { NgxMaskModule, IConfig } from 'ngx-mask'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { LaddaModule } from 'angular2-ladda';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// **************************************************
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';

// https://www.npmjs.com/package/angular-validate-br
import { AngularValidateBrLibModule } from 'angular-validate-br';

registerLocaleData(ptBr);
// **************************************************


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    LaddaModule.forRoot({
      style: "contract",
      spinnerSize: 30,
      spinnerColor: "black",
      spinnerLines: 12
    }),
    // https://www.npmjs.com/package/angular-validate-br
    AngularValidateBrLibModule
  ],
  exports: [
    NgSelectModule,
  ],
  providers:    [
    { provide: LOCALE_ID, useValue: 'pt' },
    // *************************************************
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    // *************************************************
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

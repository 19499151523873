import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from './email.service';

@Component({
    selector: 'app-modal-email-component',
    styleUrls: ['./email.component.scss'],
    templateUrl: 'email.component.html'
})
export class NgbdModalEmailComponent implements OnInit {

    loading = [false];
    submitted = false

    public form: FormGroup;

    get f() {
        return this.form.controls;
    }

    constructor(private modalService: NgbModal, private fb: FormBuilder, public _service: EmailService, public activeModal: NgbActiveModal) {

    }

    ngOnInit(): void {
        this.form = this.fb.group({
            pk_idprospeccao: [null],
            pro_email: [null, [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")
            ]]
        });
    }

    async onSubmit() {

        try {

            this.submitted = true;

            // // stop here if form is invalid
            if (this.form.invalid) {
                this.form.markAllAsTouched()
                return;
            }

            var body = this.form.value;

            this.loading[0] = true;

            const data = await this._service.create(body);

            this.form.patchValue({
                pk_idprospeccao: data.id
            })

            this.activeModal.close(this.form.value)

        } catch (error) {
            
            console.log(error)
        } finally {
            this.loading[0] = false;
        }

    }

}

import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-content',
    styleUrls: ['./endereco.component.scss'],
    template: `
    <div class="modal-header">
    <h5 class="modal-title text-center">Endereço</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
    <form>
        
            <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyName">CEP</label>
                <input class="form-control" id="applyName" type="text" placeholder="  ">
                </div>
            </div>

            <div class="col-12 col-md-6">
                <label class="form-label" for="applyName"></label>
                <p class="">Não sabe o CEP? <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Clique aqui.</a></p>
            </div>
            </div> 

            <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyEmail">Endereço</label>
                <input class="form-control" id="applyEmail" type="text" placeholder="">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyEmail">Número</label>
                <input class="form-control" id="applyEmail" type="text" placeholder="">
                </div>
            </div>
            </div>

            <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyName">Estado</label>
                <input class="form-control" id="applyName" type="text" placeholder="">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyEmail">Cidade</label>
                <input class="form-control" id="applyEmail" type="text" placeholder="">
                </div>
            </div>
            </div> <!-- / .row -->
            <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyName">Bairro</label>
                <input class="form-control" id="applyName" type="text" placeholder="">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group ">
                <label class="form-label" for="applyName">Complemento</label>
                <input class="form-control" id="applyName" type="text" placeholder="">
                </div>
            </div>
            </div> <!-- / .row -->
     </form>

    </div>
    <div class="modal-footer">
        <div class="left-side">
            <button type="button" class="btn btn-default btn-link" (click)="activeModal.close('Close click')">Cancelar</button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
            <button type="button" class="btn btn-success  btn-link" (click)="activeModal.close('Close click')">Confirmar</button>
        </div>
    </div>
    `
})
export class NgbdModalEnderecoContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) { }
}

@Component({
    selector: 'app-modal-endereco-component',
    templateUrl: './endereco.component.html'
})
export class NgbdModalEnderecoComponent {
    constructor(private modalService: NgbModal) { }
    open() {
        // const modalRef = this.modalService.open(NgbdModalContratoContent);
        const modalRef = this.modalService.open(NgbdModalEnderecoContent, { size: 'lg', backdrop: 'static' });

        modalRef.componentInstance.name = 'World';
    }
}

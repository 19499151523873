import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';


@Injectable({
  providedIn: 'root'
})
export class ProcessandoService {

  constructor(private _httpClient: HttpClient, private _appService: AppService) { }

  async getVendaPorId(id: any): Promise<any> {

    var retorno = null;

    try {
      const urlAPI = `${this._appService.getUrlAPINest()}inclusao-pf/vendas/${id}`
      retorno = await this._httpClient.get<any>(urlAPI).toPromise();

    } catch (error) {
      console.log(error)
      throw error
    }

    return retorno;
  }


}




import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { ComponentsCampanha } from './campanha/campanha.component';
import { ComponentsCheckout } from './checkout/checkout.component';
import { ComponentsConfirmacao } from './confirmacao/confirmacao.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalComponent } from './modal/modal.component';
import { NgbdModalContent } from './modal/modal.component';

import { NgbdModalContratoComponent, NgbdModalContratoContent } from './modal/contrato/contrato.component';
import { NgbdModalEnderecoComponent, NgbdModalEnderecoContent } from './modal/endereco/endereco.component';
import { NgbdModalEmailComponent } from './modal/email/email.component';

import { NgbdModalRegiaoComponent } from './modal/regiao/regiao.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgbdModalDependenteComponent } from './modal/dependente/dependente.component';
import { NgbdModalBoletoComponent } from './modal/boleto/boleto.component';
import { NgbdModalCarenciaComponent } from './modal/carencia/carencia.component';
import { NgxMaskModule } from 'ngx-mask';
import { LaddaModule } from 'angular2-ladda';
import { BlockUIModule } from 'ng-block-ui';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbdModalSemPlanoRegiaoComponent } from './modal/SemPlanoRegiao/SemPlanoRegiao.component';
import { ComponentsProcessando } from './processando/processando.component';

import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { NgbdModalCampanhaPausadaServiceComponent } from './modal/campanhaPausada/campanhaPausada.component';
import { NgbdModalYoutubeComponent } from './modal/youtube/youtube.component';
import { NgbdModalMaisInformacoesComponent } from './modal/maisInformacoes/maisInformacoes.component';
import { VisualizarContratoComponent } from './modal/visualizar-contrato/visualizar-contrato.component';
// import { OnlyNumberDirective } from 'app/directives/only-number-directive';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        ReactiveFormsModule,
        NgSelectModule,
        // OnlyNumberDirective,
        NgxMaskModule,
        LaddaModule,
        BlockUIModule.forRoot(),
        ClipboardModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    declarations: [
        ComponentsComponent,
        ComponentsCampanha,
        ComponentsCheckout,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalComponent,
        NgbdModalContent,
        NgbdModalContratoContent,
        NgbdModalContratoComponent,
        NgbdModalEnderecoContent,
        NgbdModalEnderecoComponent,
        NgbdModalEmailComponent,
        NgbdModalSemPlanoRegiaoComponent,
        NgbdModalMaisInformacoesComponent,
        NgbdModalBoletoComponent,
        NgbdModalCarenciaComponent,
        NgbdModalRegiaoComponent,
        NgbdModalCampanhaPausadaServiceComponent,
        ComponentsConfirmacao,
        ComponentsProcessando,
        NgbdModalDependenteComponent,
        NgbdModalYoutubeComponent,
        VisualizarContratoComponent

    ],
    entryComponents: [NgbdModalContent, NgbdModalContratoContent, NgbdModalRegiaoComponent, NgbdModalEmailComponent, NgbdModalDependenteComponent, NgbdModalYoutubeComponent],
    exports: [ComponentsComponent],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
    ]
})
export class ComponentsModule { }

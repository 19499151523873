import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BoletoService } from './boleto.service';

@Component({
    selector: 'app-modal-boleto-component',
    styleUrls: ['./boleto.component.scss'],
    templateUrl: 'boleto.component.html'
})
export class NgbdModalBoletoComponent implements OnInit {

    loading = [false];
    submitted = false

    public form: FormGroup;

    get f() {
        return this.form.controls;
    }

    constructor(private modalService: NgbModal, private fb: FormBuilder, public _service: BoletoService, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {

    }




    sanitizeUrl(): SafeUrl {
        let url = "https://corporativo.planoclin.com.br/SYS/Consultas/ConsultasSelecionaBanco.aspx?Parcela=3020675&tp_associadoempresa=1&Cliente=302075667&ImpressaoEmpresa=1&Vencimento=23/08/2022";
        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }


    ngOnInit(): void {
        this.form = this.fb.group({
            pro_Boleto: [null, [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
            ]]
        });
    }

    async onSubmit() {

        try {

            this.submitted = true;

            // // stop here if form is invalid
            if (this.form.invalid) {
                this.form.markAllAsTouched()
                return;
            }

            var body = this.form.value;

            this.loading[0] = true;

            const data = await this._service.create(body);

            this.activeModal.close(this.form.value)

        } catch (error) {
            
            console.log(error)
        } finally {
            this.loading[0] = false;
        }

    }

}

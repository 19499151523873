import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpEvent,
} from "@angular/common/http";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: "root",
})
export class DependenteService {
  urlAPI: string;
  constructor(
    private _httpClient: HttpClient,
    private _appService: AppService
  ) {
    this.urlAPI = this._appService.getUrlAPINest() + 'inclusao-pf/';
  }

  async create(params: any): Promise<any> {
    var retorno = null;

    try {
      // const urlAPI = `${this._appService.getUrlAPI()}clientes`
      retorno = await this._httpClient
        .post<any>(this.urlAPI + `clientes`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }

  async patch(params: any, id): Promise<any> {
    var retorno = null;

    try {
      // const urlAPI = `${this._appService.getUrlAPI()}clientes/${id}`
      retorno = await this._httpClient
        .patch<any>(this.urlAPI + `clientes/${id}`, params)
        .toPromise();
    } catch (error) {
      console.log(error);
      throw error;
    }

    return retorno;
  }
}

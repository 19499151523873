import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';


@Injectable({
  providedIn: 'root'
})
export class ConfirmacaoService {

  urlAPI: string;
  constructor(private _httpClient: HttpClient, private _appService: AppService) { 
    this.urlAPI = this._appService.getUrlAPINest() + "inclusao-pf/";
  }

  async getVendaPorId(id: any): Promise<any> {

    var retorno = null;

    try {
      
      retorno = await this._httpClient.get<any>(this.urlAPI + `vendas/${id}`).toPromise();

    } catch (error) {
      console.log(error)
      throw error
    }

    return retorno;
  }

}




import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { AppService } from 'app/app.service';

@Injectable({
    providedIn: 'root'
})
export class MaisInformacoesService {

    urlAPI: string
    constructor(private _httpClient: HttpClient, private _appService: AppService) {
        this.urlAPI = this._appService.getUrlAPINest() + "inclusao-pf/";
     }


    async getPlanoPorID(id: number): Promise<any> {

        var retorno = null;

        try {
                   

            // const urlAPI = `${this._appService.getUrlAPI()}planos/` +id
            retorno = await this._httpClient.get<any>(this.urlAPI+ `planos/${id}`).toPromise();
            
            let data = retorno[0];

        } catch (error) {
            
            console.log(error)
            throw error
        }

        return retorno;

    }


   



}




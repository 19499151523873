import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBoletoComponent } from '../modal/boleto/boleto.component';


import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { NgbdModalCarenciaComponent } from '../modal/carencia/carencia.component';
import { ProcessandoService } from './processando.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-components',
    templateUrl: 'processando.component.html',
    // styles: [`
    // ngb-progressbar {
    //     margin-top: 5rem;
    // }
    // `,
    // './confirmacao.component.scss']


    styleUrls: ['./processando.component.scss']


})



export class ComponentsProcessando implements OnInit {
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: { year: number, month: number };
    model: NgbDateStruct;

    venda: any = {};

    constructor(private renderer: Renderer2, private modalService: NgbModal, private _service: ProcessandoService,
        private activatedRoute: ActivatedRoute) { }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    ngOnInit() {

        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function () {
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function () {
                input_group[i].classList.remove('input-group-focus');
            });
        }

        this.loadAsync();
    }

    //Chamar PopUpBoleto
    open() {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
        };

        let modal = this.modalService.open(NgbdModalBoletoComponent, ngbModalOptions); // Render modal with modal component  

        modal.result.then((result) => {


        }, (reason) => {
            // modal foi cancelado/fechado
        })
    }

    print(): void {

        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0, padding=20px, height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Confirmmação de compra - Agiben</title>
              <style>
                body {
                    margin: 0;
                    font-family: Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI",Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
                    font-size: .894rem;
                    font-weight: 400;
                    line-height: 1.47;
                    color: #4e5155;
                    text-align: left;
                    background-color: #fff;
                    padding: 40px;          
                    zoom: 60%;            
                }
                
                .lista-telefone {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .hidden-print {display: none !important}
                
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

    loadAsync() {

        try {
            //this.venda = JSON.parse(localStorage.getItem("VENDA_OK"));
            this.getByID()
        } catch (error) {

        }

    }

    openCarencia() {


        let ngbModalOptions: NgbModalOptions = {
            //backdrop: 'static',
            keyboard: true,
            size: 'lg'
        };

        let fp = {
            pre_descricao: this.venda.pre_descricao,
            pla_nome: this.venda.pla_nome,
            fpa_nome: this.venda.fpa_nome
        }

        let modal = this.modalService.open(NgbdModalCarenciaComponent, ngbModalOptions); // Render modal with modal component  
        (<NgbdModalCarenciaComponent>modal.componentInstance).forma_pagamento = fp;

        modal.result.then((result) => {
            // debugger            
        }, (reason) => {
            // modal foi cancelado/fechado
        })
    }



    isMensal(): boolean {
        return this.venda.fpa_nome && this.venda.fpa_nome.toLowerCase().indexOf("mensal") >= 0;
    }


    getDateTimePadrao(o) {

        var date;

        if (o.ven_datahora != null) {
            return moment(o.ven_datahora, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm');
        } else {
            return moment().format('DD/MM/YYYY HH:mm');
        }
    }

    getDescricaoGrauParentesto(o) {

        // <ng-option value="1">TITULAR</ng-option>
        // <ng-option value="3">CONJUGE/COMPANHEIRO</ng-option>
        // <ng-option value="4">FILHO</ng-option>
        // <ng-option value="6">ENTEADO</ng-option>
        // <ng-option value="8">PAI/MAE</ng-option>
        // <ng-option value="10">AGREGADOS/OUTROS</ng-option>


        switch (o) {
            case "1":
                return "TITULAR"
            case "3":
                return "CONJUGE/COMPANHEIRO"
            case "4":
                return "FILHO(a)"
            case "6":
                return "ENTEADO"
            case "8":
                return "PAI/MAE"
            case "10":
                return "AGREGADOS/OUTROS"
            default:
                return "DESCONHECIDO"
        }
    }

    monstrarDependete(): boolean {
        let retorno = false;

        if (this.venda != null && this.venda.dependentes != null && this.venda.dependentes.length > 0) {
            retorno = true;
        }

        return retorno
    }

    isBoleto(): boolean {
        return this.venda.fpa_nome && this.venda.fpa_nome.toLowerCase().indexOf("boleto") >= 0;
    }

    async getByID() {

        try {

            
            let venda_id = atob(this.activatedRoute.snapshot.params.id); // this will decode the data 

            let pk_idvenda = await +venda_id;

            this.venda = await this._service.getVendaPorId(pk_idvenda);

            // let pk_idvenda = await +this.activatedRoute.snapshot.params.id;

            // this.venda = await this._service.getVendaPorId(pk_idvenda);

            console.log(this.venda);

        } catch (error) {
            console.error(error)
        }
    }

    /**
    * 
    * @param value E: Esperando, A: Aprovado. R: Rejeitado
    */
    getStatusPagamento(value) {
        switch (value) {
            case 'E':
                return 'Esperando'
            case 'A':
                return 'Aprovado'
            case 'E':
                return 'Rejeitado'
            default:
                return 'Indisponivel'
        }
    }

}

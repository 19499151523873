import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarenciaService } from './carencia.service';

@Component({
    selector: 'app-modal-carencia-component',
    styleUrls: ['./carencia.component.scss'],
    templateUrl: 'carencia.component.html'
})
export class NgbdModalCarenciaComponent implements OnInit {

    loading = [false];
    submitted = false
    plano_regiao = 161;

    @Input() forma_pagamento;

    carencia
    pla_nome
    fpa_nome

    state: any = {
        carenica: null,
        loading: [false, false, false, false],
        is404: [false, false, false, false],
        disabled: false,
    }


    public form: FormGroup;

    get f() {
        return this.form.controls;
    }

    constructor(private modalService: NgbModal, private fb: FormBuilder, public _service: CarenciaService, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {

    }


    ngOnInit(): void {

        this.carencia = this.forma_pagamento.pre_descricao
        this.pla_nome = this.forma_pagamento.pla_nome
        this.fpa_nome = this.forma_pagamento.fpa_nome
    }




}
